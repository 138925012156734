import { render, staticRenderFns } from "./HomeScreen.vue?vue&type=template&id=33922c87&"
import script from "./HomeScreen.vue?vue&type=script&lang=ts&"
export * from "./HomeScreen.vue?vue&type=script&lang=ts&"
import style0 from "./HomeScreen.vue?vue&type=style&index=0&id=33922c87&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QInnerLoading,QCard,QCardSection,QPagination,QSkeleton});
